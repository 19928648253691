<template>
    <section>
        <b-card class="responsiveCard">
            <div class="titleModule mb-2">
                {{ staticLabels.moduleName }}
            </div>
            <div class="cycleProgress">
                {{ moduleData.progressDescription }}
            </div>
            <div class="dateProgress mb-1">
                {{ moduleData.progressDate }}
            </div>
            <div class="mb-3">
                <b-progress
                    max="100"
                    :value="moduleData.progressBarPercentage"
                    height="12px"
                    variant="info"
                >
                </b-progress>
            </div>
            <div class="d-flex text-center justify-content-center">
                <div
                    class="d-flex ml-1"
                >
                    <div>
                        <img
                            :src="participant.icon"
                            :alt="participant.altIcone"
                            class="iconSize"
                        />
                    </div>
                    <div class="ml-1 leftMarginLabelAndPercentageResponsive">
                        <div class="labelParticipantsAnswersMyBusiness">
                            Participantes
                        </div>
                        <div class="numberOfParticipantsAnswersPercentage">
                            {{ moduleData.participants }}
                        </div>
                    </div>
                </div>
                <div class="horizontalBar"/>
                <div
                    class="d-flex mr-1"
                >
                    <div>
                        <img
                            :src="answer.icon"
                            :alt="answer.altIcone"
                            class="iconSize"
                        />
                    </div>
                    <div class="ml-1 leftMarginLabelAndPercentageResponsive">
                        <div class="labelParticipantsAnswersMyBusiness">
                            Respostas
                        </div>
                        <div class="numberOfParticipantsAnswersPercentage">
                            {{ moduleData.answers }}
                        </div>
                    </div>
                </div>
            </div>
            <hr class="verticalBar my-2"/>
            <div class="titlePercentage">
                {{ staticLabels.titleLabelMyBusiness }}
            </div>
            <div :id="'tooltip-target-'+staticLabels.moduleName" class="subtitlePercentage mb-2">
                {{ staticLabels.subtitleLabelMyBusiness }}
            </div>
            <b-tooltip v-if="showTooltip" :target="'tooltip-target-'+staticLabels.moduleName" triggers="hover">
                {{ staticLabels.subtitleLabelMyBusiness }}
            </b-tooltip>
            <div
                class="d-flex justify-content-center text-center"
            >
                <div class="d-flex percentageContent">
                    <div>
                        <img
                            :src="star.icon"
                            :alt="star.altIcone"
                            class="iconSize"
                        />
                    </div>
                    <div class="ml-1 leftMarginLabelAndPercentageResponsive">
                        <div class="labelParticipantsAnswersMyBusiness">
                            Minha empresa
                        </div>
                        <div class="numberOfParticipantsAnswersPercentage">
                            {{ moduleData.myBusinessProportion ? moduleData.myBusinessProportion.toString().replace(".", ",") : null  }}%
                        </div>
                    </div>
                </div>
            </div>
        </b-card>
    </section>
</template>

<script>
import { BCard, BProgress, BTooltip } from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BProgress,
        BTooltip
    },

    props: {
        moduleData: {
            type: Object,
            required: true
        },
        staticLabels: {
            type: Object,
            required: true
        },
        participant: {
            type: Object,
            required: true
        },
        answer: {
            type: Object,
            required: true
        },
        star: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showTooltip: this.verifyShowTooltip()
        }
    },

    mounted(){
        window.addEventListener('resize', () => {
            this.showTooltip = this.verifyShowTooltip();
        });
    },

    methods: {
        verifyShowTooltip(){
            return window.innerWidth >= 1200 && window.innerWidth <= 1300;
        },
    }
}
</script>
<style lang="scss">
    .titleModule{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #5E5873;
    }

    .cycleProgress{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #5E5873;
    }

    .dateProgress{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #B9B9C3;
    }

    .bg-info {
        background: linear-gradient(73.79deg, #7030A0 7.39%, #3DAEDE 48.81%, #94E075 92%) !important;
    }

    .labelParticipantsAnswersMyBusiness{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #B9B9C3;
    }

    .numberOfParticipantsAnswersPercentage{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #5E5873;
    }

    .horizontalBar{
        min-width: 1px;
        max-width: 1px;
        height: 35px;
        background-color: #ebe9f1;
        margin: 8px 25px 5px 25px;
    }

    .verticalBar{
        border-top: 1px solid #ebe9f1;
    }

    .titlePercentage{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #5E5873;
    }

    .subtitlePercentage{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #B9B9C3;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .percentageContent{
        background-color: #F8F8F8;
        border-radius: 6px;
        padding: 20px;
    }

    .iconSize{
        width: 48px;
        height: 48px;
    }

    @media (min-width: 576px) and (max-width: 991px) {
        .responsiveCard{
            text-align: center;
        }
    }

    @media (max-width: 370px) {
        .iconSize{
            width: 40px;
            height: 40px;
            margin-top: 7px;
        }

        .labelParticipantsAnswersMyBusiness{
            font-size: 10px;
        }

        .numberOfParticipantsAnswersPercentage{
            font-size: 24px;
        }

        .horizontalBar{
            margin: 8px 10px 5px 10px;
        }

        .leftMarginLabelAndPercentageResponsive{
            margin-left: 7px !important;
        }
    }
</style>
