<template>
  <div>
      <section v-if="$can(actionRule.visualizar, entityRule.portal.inicio.sesi)" id="welcome-image">
          <div class="welcome-img">
              <img :src="homeImg" alt="imagemBoasVindasSESI">
          </div>
      </section>
      <section v-else id="welcome-image-business" class="mb-2">
          <div class="welcome-img-business-title">
              Bem-vindo ao
          </div>
          <div>
              <img class="welcome-img-business" :src="businessImg" alt="imagemBoasVindas">
          </div>
      </section>
      <b-overlay :show="loadingShow" opacity="1">
          <main v-if="!loadingShow">
              <b-row>
                  <b-col
                      v-if="$can(actionRule.visualizar, entityRule.moduloSaudeMental.miniDashboardPainelInicial)"
                      xl="5"
                      lg="6"
                  >
                      <div
                          id="saude-mental-dashboard"
                          @click="redirectMainDashboard(saudeMentalCycle.idModule)"
                      >
                          <mini-dashboard
                              v-if="!Object.values(saudeMentalCycle).every(x => x === null || x === '' || typeof x === 'undefined')"
                              :moduleData="saudeMentalCycle"
                              :staticLabels="saudeMentalStaticLabels"
                              :participant="participant"
                              :answer="answer"
                              :star="star"
                          />
                          <empty-mini-dashboard
                              v-else
                              :moduleLabel="saudeMentalStaticLabels.moduleName"
                          />
                      </div>
                  </b-col>
                  <b-col
                      v-if="$can(actionRule.visualizar, entityRule.moduloPlanejaSaude.miniDashboardPainelInicial)"
                      xl="5"
                      lg="6"
                  >
                      <div
                          id="planeja-saude-dashboard"
                          @click="redirectMainDashboard(planejaSaudeCycle.idModule)"
                      >
                          <mini-dashboard
                          v-if="!Object.values(planejaSaudeCycle).every(x => x === null || x === '' || typeof x === 'undefined')"

                          :moduleData="planejaSaudeCycle"
                          :staticLabels="planejaSaudeStaticLabels"
                          :participant="participant"
                          :answer="answer"
                          :star="star"
                          />
                          <empty-mini-dashboard
                              v-else
                              :moduleLabel="planejaSaudeStaticLabels.moduleName"
                          />
                      </div>
                  </b-col>
              </b-row>
          </main>
      </b-overlay>
  </div>
</template>

<script>

import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'
import { BRow, BCol, BCard, BProgress, BOverlay } from 'bootstrap-vue'
import moment from 'moment'
import ParticipantIcon from "@/assets/custom-icons/participants.svg";
import AnswerIcon from "@/assets/custom-icons/answers.svg";
import StarIcon from "@/assets/custom-icons/mybusinessstar.svg";
import miniDashboard from "./bem-vindo-component/MiniDashboard.vue";
import EmptyMiniDashboard from "./bem-vindo-component/EmptyMiniDashboard.vue";
import { $tipos } from '@/custom-enum/tipos-enum.js';

export default {
  components: {
      BRow, BCol, BCard, BProgress, BOverlay,
      miniDashboard,
      EmptyMiniDashboard
  },

  setup() {
      return {
          ParticipantIcon,
          AnswerIcon,
          StarIcon
      };
  },

  data() {
      return {
          entityRule: $regraEntidade,
          actionRule: $regraAcao,
          homeImg: require('@/assets/images/home/welcome-img.png'),
          businessImg: require('@/assets/images/home/clienteSesiSaudeHorizontal.svg'),
          loadingShow: true,
          saudeMentalCycle: {
              idModule: null,
              progressDescription: null,
              progressDate: null,
              participants: null,
              answers: null,
              progressBarPercentage: null,
              myBusinessProportion: null
          },
          saudeMentalStaticLabels:{
              moduleName: 'Saúde Mental',
              titleLabelMyBusiness: 'Estatística dos níveis de depressão',
              subtitleLabelMyBusiness: '*Com base nas pessoas classificadas nas escalas 3 e 4.'
          },
          planejaSaudeCycle: {
              idModule: null,
              progressDescription: null,
              progressDate: null,
              participants: null,
              answers: null,
              progressBarPercentage: null,
              myBusinessProportion: null
          },
          planejaSaudeStaticLabels:{
              moduleName: 'Planeja Saúde',
              titleLabelMyBusiness: 'Índice de produtividade',
              subtitleLabelMyBusiness: '*De todos os colaboradores participantes.'
          },
          participant: {
              icon: ParticipantIcon,
              altIcon: 'icone-participantes'
          },
          answer: {
              icon: AnswerIcon,
              altIcon: 'icone-respostas'
          },
          star: {
              icon: StarIcon,
              altIcon: 'icone-minha-empresa'
          }
      }
  },

  async mounted() {
    await this.dashboardsFindData();
  },

  methods: {
      async dashboardsFindData() {
          if(this.$store.state.sessions.userData.empresa?.id_empresa){
              await this.$http.get(this.$api.MiniDashboardsPainelInicial(this.$store.state.sessions.userData.empresa.id_empresa))
              .then(res => {
                  this.prepareReceivedData(res.data)
              })
              .catch(() => {
                  return this.$router.push({ name: 'misc-not-authorized' });
              })
          }
          this.loadingShow = false;
      },

      prepareReceivedData(data){
          for (let element of data) {
              if(element.idmodulo == $tipos.documentos.saudeMental){
                  this.saudeMentalCycle.idModule = element.idmodulo;
                  this.saudeMentalCycle.progressDescription = element.descricao + " - " + element.situacao;
                  this.saudeMentalCycle.progressDate = moment(element.inicio, "YYYY-MM-DD").format('DD/MM/YY') + " - " + moment(element.termino, "YYYY-MM-DD").format('DD/MM/YY');
                  this.saudeMentalCycle.participants = element.participantes;
                  this.saudeMentalCycle.answers = element.respostas;
                  this.saudeMentalCycle.progressBarPercentage = ((element.respostas / element.participantes) * 100).toFixed(0);
                  this.saudeMentalCycle.myBusinessProportion = +(parseFloat(element.percentual).toFixed(1));
              }
              if(element.idmodulo == $tipos.documentos.planejaSaude){
                  this.planejaSaudeCycle.idModule = element.idmodulo;
                  this.planejaSaudeCycle.progressDescription = element.descricao + " - " + element.situacao;
                  this.planejaSaudeCycle.progressDate = moment(element.inicio, "YYYY-MM-DD").format('DD/MM/YY') + " - " + moment(element.termino, "YYYY-MM-DD").format('DD/MM/YY');
                  this.planejaSaudeCycle.participants = element.participantes;
                  this.planejaSaudeCycle.answers = element.respostas;
                  this.planejaSaudeCycle.progressBarPercentage = ((element.respostas / element.participantes) * 100).toFixed(0);
                  this.planejaSaudeCycle.myBusinessProportion = +(parseFloat(element.percentual).toFixed(1));
              }
          }
      },

      redirectMainDashboard(idModule){
          switch (idModule) {
              case $tipos.documentos.saudeMental:
                  this.$router.push(
                      {
                          name: 'saude-mental-painel'
                      }
                  );
                  break;
              case $tipos.documentos.planejaSaude:
                  this.$router.push(
                      {
                          name: 'painel-planeja-saude'
                      }
                  );
                  break;
              default:
                  break;
          }
      }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  .welcome-img-business-title{
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #5E5873;
  }

  #saude-mental-dashboard, #planeja-saude-dashboard{
      cursor: pointer;
  }

  @media (min-width: 576px) and (max-width: 991px) {
      #welcome-image-business{
          text-align: center;
      }
  }

  @media (max-width: 575px) {
      .welcome-img-business-title{
          font-size: 30px;
      }

      .welcome-img-business{
          max-width: 280px;
      }
  }
</style>
